/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { myStyles } from '../signuppopup/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'gatsby';
import '../../styles.scss';
import clsx from 'clsx';
import { navigate } from 'gatsby-link';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import IconButton from '@material-ui/core/IconButton';
import ThemeContext from '../../utils/theme-context';
import { themeStyles } from '../../styles';
import { AstContextDispatch } from '../../utils/ast-context';
import Helper from './helper';
import { routerHandling } from './form-validation';

export default function PropertyTypes({ data, context }) {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const dispatch = useContext(AstContextDispatch);
  const [isActive] = useState(true);
  const [helper, setHelper] = useState(false);
  const [code, setCode] = useState([]);
  const [lead, setLead] = useState({});

  // useEffect(() => {
  //   const getLead = JSON.parse(localStorage.getItem('lead'));
  //   setLead(getLead);
  //   if (getLead?.buyPropTypeCode) { setCode([...getLead.buyPropTypeCode]); }
  // }, []);

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead);
    if (getLead?.sellPropTypeCode && context.screenType.toUpperCase().includes('SELL')) {
      setCode([...getLead.sellPropTypeCode]);
    } else if (getLead?.buyPropTypeCode && context.screenType.toUpperCase().includes('BUY')) {
      setCode([...getLead.buyPropTypeCode]);
    }
  }, []);

  const handleToggle = (event) => {
    if (context.type) {
      if (context.screenType.toUpperCase().includes('SELL')) {
        setCode([event]);
      } else if (context.screenType.toUpperCase().includes('BUY')) {
        if (code.includes(event)) {
          const newCode = code.filter((propCode) => {
            return propCode !== event;
          });
          setCode([...newCode]);
        } else {
          setCode([...code, event]);
        }
      }
    } else {
      if (context.screenType.toUpperCase().includes('BUY')) {
        if (code.includes(event)) {
          const newCode = code.filter((propCode) => {
            return propCode !== event;
          });
          setCode([...newCode]);
        } else {
          setCode([...code, event]);
        }
      } else {
        setCode([event]);
      }
    }
  };

  const handlerClick = (path) => {
    const types = {
    };
    if (context.type) {
      if (context.screenType.toUpperCase().includes('SELL')) {
        types.sellPropTypeCode = [];
        types.sellPropTypeCode.push(code[0]);
      } else if (context.screenType.toUpperCase().includes('BUY')) {
        types.buyPropTypeCode = [];
        types.buyPropTypeCode.push(...code);
      }
    } else {
      if (context.screenType.toUpperCase().includes('BUY')) {
        types.buyPropTypeCode = [];
        types.buyPropTypeCode.push(...code);
      } else {
        types.sellPropTypeCode = [];
        types.sellPropTypeCode.push(code[0]);
      }
    }
    dispatch({ type: 'SET_BUY_PROPERTY_TYPE', value: { ...lead, ...types } });
    if (code.length > 0) navigate(path);
  };

  const helperShow = () => {
    setHelper(true);
  };

  const helperHide = () => {
    setHelper(false);
  };

  // const closeModal = () => {
  //   navigate('/');
  //   localStorage.removeItem('lead');
  // };

  return (
    <Container id="property-type" className={classes.root}>
      <Grid container>
        {helper && (
        <>
          <Helper helperData={data} contextData={context} helperSwitch={helperHide} />
        </>
        )}
        { !helper
        && (
          <>
            <div className={classes.buy}>
              <div className={classes.prev}>
                <Link to={routerHandling(context, 'prev')}>
                  <IconButton aria-label="back" className={classes.backPage}>
                    <ArrowBackIcon />
                  </IconButton>
                </Link>
                {data && data.showHelperText && (
                  <Typography variant="caption">
                    <div role="dialog" aria-modal="true" aria-label="Have Questions">
                    <Button onClick={helperShow} className={classes.haveQuestions}>
                      <ContactSupportIcon />
                      Have Questions
                    </Button>
                    </div>
                  </Typography>
                )}
                {/* <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
                  <CloseIcon />
                </IconButton> */}
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <Typography variant="h2" component="h2" className={classes.internalContent}>
                    {data.question.internal.content}
                  </Typography>
                  <Typography className={classes.internalSubText}>
                    {context.subquest}
                  </Typography>
                </Paper>
              </Grid>
              <Grid container maxWidth="lg" spacing={3} className={clsx(classes.gridFix, classes.customPaddingBtm, classes.cardsGrid)}>
                {(context.screenType === 'Sell - Property Type' && context.type === 'both') ? (
                  <>
                    {context.sellPropertyTypes.map((type) => (
                      <Grid item lg={4} xs={12} id={`sell-prop-type-${type.label}`} onClick={() => handleToggle(type.code)} container direction="row" justifyContent="center" alignItems="center">
                        <Card component="button" tabIndex="0" className={clsx(classes.signIncard, classes.signInGridCard, isActive && (code.includes(type.code)) ? `${classes.selected}` : '')} aria-current={!!(isActive && (code.includes(type.code)))}>
                          <CardContent classes={{ root: classes.cardContentSignIn }}>
                            <img className={clsx(classes.cardImg, classes.cardImgMargin)} src={`https:${type.icon.file.url}`} alt={type?.icon?.description} />
                            <Typography variant="h4" className={clsx(classes.cardText, classes.labelMargin)}>{type.label}</Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    {context.propertyTypes.map((type) => (
                      <Grid item lg={4} xs={12} id={`buy-prop-type-${type.label}`} onClick={() => handleToggle(type.code)} container direction="row" justifyContent="center" alignItems="center">
                        <Card component="button" tabIndex="0" className={clsx(classes.signIncard, classes.signInGridCard, isActive && (code.includes(type.code)) ? `${classes.selected}` : '')} aria-current={!!(isActive && (code.includes(type.code)))}>
                          <CardContent classes={{ root: classes.cardContentSignIn }}>
                            <img className={clsx(classes.cardImg, classes.cardImgMargin)} src={`https:${type.icon.file.url}`} alt={type?.icon?.description} />
                            <Typography variant="h4" className={clsx(classes.cardText, classes.labelMargin)}>{type.label}</Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className={clsx(classes.paper, classes.typoAlign)}>
                  <Button id="property-type-next-btn" disabled={(!(code.length > 0))} onClick={() => handlerClick(routerHandling(context, 'next'))} variant="contained" size="large" className={clsx(globalThemeCss.containedBtn, classes.nextButton)}>Next</Button>
                </Paper>
              </Grid>
            </div>
          </>
        )}
      </Grid>
    </Container>
  );
}
