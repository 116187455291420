/* eslint-disable no-unused-vars */
/* eslint-disable arrow-parens */
/* eslint-disable arrow-spacing */
/* eslint-disable prefer-template */
import React, { useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import clsx from 'clsx';
import { myStyles } from '../signuppopup/styles';
import { themeStyles } from '../../styles';
import ThemeContext from '../../utils/theme-context';

export default function cardConfirmationView({ details, selectProfile, lead }) {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);

  return (
    <Card component="button" id="agent-card-confirmation" role="group" aria-label={details.isBestMatch ? 'Selected Best Match Agent' : 'Selected Agent'} className={clsx(classes.CardConfirmationroot, classes.commonText, lead && lead.operationType !== 'Both' ? classes.singleAgentCard : '')}>
      <Badge
        badgeContent="BEST MATCH"
        color="primary"
        component="div"
        style={{
          width: '70%',
          flexDirection: 'column',
          padding: '33px'
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        classes={{
          anchorOriginTopLeftRectangle: details.isBestMatch ? classes.badgeMargin : '',
          badge: classes.primaryBackgroundColor
        }}
        className={classes.badgeElement}
        invisible={!details.isBestMatch}
      >
        <CardMedia
          id="agent-card-confirmation-picture"
          className={classes.CardConfirmationmedia}
          image={details.photo}
          title={details.agentFName}
        />
        <CardContent className={clsx(classes.CardConfirmationcontent, classes.commonText)}>
          <Typography id="agent-card-confirmation-name" variant="h5" color="textSecondary" component="button" style={{ textDecoration: 'underline', marginBottom: '5px', backgroundColor: 'transparent' }} onClick={e=> { selectProfile(details); }}>
            {details.agentFName + ' ' + details.agentLName}
          </Typography>
          <Typography style={{ margin: '0px' }} className={clsx(classes.commonText, classes.agentConfirmationDetail)}>{details.company}</Typography>
          <Typography className={clsx(classes.commonText, classes.agentConfirmationDetail)}>
            {details.expInYears ? details.expInYears + ' Years of Experience' : <span>&nbsp; </span>}
          </Typography>
        </CardContent>
      </Badge>
    </Card>
  );
}
