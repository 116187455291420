/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { themeStyles } from '../../styles';
import { myStyles } from '../signuppopup/styles';
import { routerHandling } from './form-validation';
import Helper from './helper';

export default function LegalAgreement({ data, context }) {
  const classes = myStyles();
  const themeClass = themeStyles();
  const [helper, setHelper] = useState(false);

  const helperShow = () => {
    setHelper(true);
  };

  const helperHide = () => {
    setHelper(false);
  };

  return (
    <div className={classes.root}>
      {helper && (
        <>
          <Helper helperData={data} contextData={context} helperSwitch={helperHide} />
        </>
      )}
      {!helper
      && (
        <>
          <div id="legal-agreements" className={classes.prev}>
            <Link to={routerHandling(context, 'prev')}>
              <div className={classes.backPage}>
                <ArrowBackIcon />
                {' '}
                Previous
              </div>
            </Link>
            <div className={classes.backPage}>
              {data && data.showHelperText && (
                <Typography variant="caption">
                  <div role="dialog" aria-modal="true" aria-label="Have Questions">
                  <Button onClick={helperShow} className={classes.haveQuestions}>
                    <ContactSupportIcon />
                    Have Questions
                  </Button>
                  </div>
                </Typography>
              )}
            </div>
            {/* <IconButton aria-label="close" className={classes.margin} onClick={() => navigate('/')}>
              <CloseIcon />
            </IconButton> */}
          </div>
          <Container maxWidth="sm" className={classes.amount}>
            <Grid container spacing={3} />
          </Container>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Link to={routerHandling(context, 'next')}>
              <Button id="legal-agreements-next-btn" variant="contained" size="large" className={clsx(themeClass.containedBtn, themeClass.themePrimaryBtn)}>
                Next
              </Button>

            </Link>
          </Grid>
          <Button id="legal-agreements-disclaimer" className={classes.haveQuestions}>Reward Disclaimer</Button>
        </>
      )}
    </div>
  );
}
