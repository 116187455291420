/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { myStyles } from '../signuppopup/styles';
import SEO from '../seo-component/seo';

export default function RewardDisclaimer({ disclaimerData, disclaimerSwitch, title }) {
  const classes = myStyles();

  // const closeModal = () => {
  //   navigate('/');
  //   localStorage.removeItem('lead');
  //   localStorage.removeItem('rewards');
  //   localStorage.removeItem('mobile');
  //   localStorage.removeItem('client-details');
  //   localStorage.removeItem('mongoContactId');
  // };

  return (
    <Grid role="dialog" aria-modal="true" aria-label={title} id="reward-disclaimer" container>
      <SEO title={title} />
      <div className={classes.buy}>
        <div className={classes.prev}>
          <div className={classes.backPage}>
            <IconButton aria-label="back" onClick={disclaimerSwitch}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div className={classes.backPage}> </div>
          {/* <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
            <CloseIcon />
          </IconButton> */}
        </div>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Paper className={classes.paper}>
            <Typography variant="body2" className={classes.richTextPara}>
              {documentToReactComponents(JSON.parse(disclaimerData.raw))}
            </Typography>
          </Paper>
        </Grid>
      </div>
    </Grid>
  );
}
