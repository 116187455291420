/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Link } from 'gatsby';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { navigate } from 'gatsby-link';
import IconButton from '@material-ui/core/IconButton';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { myStyles } from '../signuppopup/styles';
import { themeStyles } from '../../styles';
import { AstContextDispatch } from '../../utils/ast-context';
import { routerHandling } from './form-validation';
import ThemeContext from '../../utils/theme-context';

export default function BuySell({ data, context }) {
  const classes = myStyles();
  const dispatch = useContext(AstContextDispatch);
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [selectedState, setSelectedState] = React.useState('');
  const [nextDisabled, setNextDisabled] = useState(true);
  const [lead, setLead] = useState({});

  const getLeadDetails = () => {
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead || {});
    return getLead;
  };

  const handleClick = (path) => {
    console.log('handleYesConsole', lead);
    dispatch({
      type: 'SET_RESIDENCE_DISCLAIMER',
      value: {
        ...lead, stateOfResd: selectedState
      }
    });
    navigate(path);
  };

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    const value = getLeadDetails();
    console.log(value, 'getleadtDeatils value in residence disclaimer');
    setNextDisabled(!value?.stateOfResd?.name);
  }, []);

  useEffect(() => {
    if (lead.stateOfResd && selectedState === '') {
      setNextDisabled(false);
      setSelectedState(lead.stateOfResd);
    } else if (selectedState) {
      setNextDisabled(false);
    } else {
      setNextDisabled(true);
    }
  });

  const addSRMessage = (text) => {
    const tag = document.createElement('p');
    const textEle = document.createTextNode(text);
    tag.appendChild(textEle);
    const element = document.getElementById('sr-msg');
    element.innerHTML = '';
    element.appendChild(tag);
  };
  const removeSRMessage = () => {
    const element = document.getElementById('sr-msg');
    element.innerHTML = '';
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      updateSRMessage(selectedState);
    }, 500);

    return () => clearTimeout(timer);
  }, [selectedState]);

  const updateSRMessage = (selectedState) => {
    if(selectedState?.name === 'California'){
      const msg = documentToReactComponents(JSON.parse(data?.residenceLegalDisclaimer?.raw));
      let text = "";
      msg.forEach(element => {
        if (element.props && element.props.children) {
          element.props.children.forEach(child => {
            if (typeof child === "string") {
              text += child;
            } else if (child.props && child.props.children) {
              text += child.props.children;
            }
          });
        }
      });
      addSRMessage(text);
    }
    else{
      removeSRMessage();
    }
  };

  const stateList = [
    { name: 'Alabama', shortName: 'AL' },
    { name: 'Alaska', shortName: 'AK' },
    { name: 'Arizona', shortName: 'AZ' },
    { name: 'Arkansas', shortName: 'AR' },
    { name: 'California', shortName: 'CA' },
    { name: 'Colorado', shortName: 'CO' },
    { name: 'Connecticut', shortName: 'CT' },
    { name: 'Delaware', shortName: 'DE' },
    { name: 'District of Columbia', shortName: 'DC' },
    { name: 'Florida', shortName: 'FL' },
    { name: 'Georgia', shortName: 'GA' },
    { name: 'Hawaii', shortName: 'HI' },
    { name: 'Idaho', shortName: 'ID' },
    { name: 'Illinois', shortName: 'IL' },
    { name: 'Indiana', shortName: 'IN' },
    { name: 'Iowa', shortName: 'IA' },
    { name: 'Kansa', shortName: 'KS' },
    { name: 'Kentucky', shortName: 'KY' },
    { name: 'Lousiana', shortName: 'LA' },
    { name: 'Maine', shortName: 'ME' },
    { name: 'Maryland', shortName: 'MD' },
    { name: 'Massachusetts', shortName: 'MA' },
    { name: 'Michigan', shortName: 'MI' },
    { name: 'Minnesota', shortName: 'MN' },
    { name: 'Mississippi', shortName: 'MS' },
    { name: 'Missouri', shortName: 'MO' },
    { name: 'Montana', shortName: 'MT' },
    { name: 'Nebraska', shortName: 'NE' },
    { name: 'Nevada', shortName: 'NV' },
    { name: 'New Hampshire', shortName: 'NH' },
    { name: 'New Jersey', shortName: 'NJ' },
    { name: 'New Mexico', shortName: 'NM' },
    { name: 'New York', shortName: 'NY' },
    { name: 'North Carolina', shortName: 'NC' },
    { name: 'North Dakota', shortName: 'ND' },
    { name: 'Ohio', shortName: 'OH' },
    { name: 'Oklahoma', shortName: 'OK' },
    { name: 'Oregon', shortName: 'OR' },
    { name: 'Pennsylvania', shortName: 'PA' },
    { name: 'Rhode Island', shortName: 'RI' },
    { name: 'South Carolina', shortName: 'SC' },
    { name: 'South Dakota', shortName: 'SD' },
    { name: 'Tennessee', shortName: 'TN' },
    { name: 'Texas', shortName: 'TX' },
    { name: 'Utah', shortName: 'UT' },
    { name: 'Vermont', shortName: 'VT' },
    { name: 'Virginia', shortName: 'VA' },
    { name: 'Washington', shortName: 'WA' },
    { name: 'West Virginia', shortName: 'WV' },
    { name: 'Wisconsin', shortName: 'WI' },
    { name: 'Wyoming', shortName: 'WY' }
  ];
  console.log('beforeReturnConsole', lead?.stateOfResd?.name);
  return (
    <>
      <Container id="ast-service-selection" className={clsx(classes.root, classes.astServiceSelection)}>
        <Grid container>
          <>
            <div className={classes.buy}>
              <div className={classes.prev}>
                <Link to={routerHandling(context, 'prev')}>
                  <IconButton aria-label="back" className={classes.backPage}>
                    <ArrowBackIcon />
                  </IconButton>
                </Link>
                {data && data.showHelperText && (
                  <Typography variant="caption">
                    <div role="dialog" aria-modal="true" aria-label="Have Questions">
                      <Button onClick={helperShow} className={classes.haveQuestions}>
                        <ContactSupportIcon />
                        Have Questions
                      </Button>
                    </div>
                  </Typography>
                )}
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <Typography variant="h2" className={classes.internalContent}>{data.question.internal.content}</Typography>
                  <Typography className={classes.internalSubText}>{data.questionSubtext}</Typography>
                </Paper>
              </Grid>
              <Grid
                id="lead-form-residence-legal-disclaimer-select-state"
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ display: 'inline-grid', justifyItems: 'center', padding: '16px' }}
              >
                <Autocomplete
                  id="select-state-box"
                  role="listbox"
                  aria-describedby="sr-msg"
                  // aria-describedby="lead-form-residence-legal-disclaimer-legaltext"
                  options={stateList}
                  getOptionLabel={(option) => option.name}
                  defaultValue={{ name: lead?.stateOfResd?.name }}
                  value={selectedState}
                  style={{ width: 300, margin: '30px 0px 35px 0px' }}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      {...params}
                      label="Select State"
                    />
                  )}
                  selectedState={selectedState}
                  onChange={(event, newValue) => {
                    setSelectedState(newValue);
                    updateSRMessage();
                  }}
                />
                <div role="alert" id="sr-msg"  className={classes.sronly} aria-live="polite" aria-atomic="true" />
                {selectedState?.name === 'California' && (
                  <Typography id="legal-text" variant="body2" className={classes.legalResidencePara}>
                    {documentToReactComponents(JSON.parse(data.residenceLegalDisclaimer.raw))}
                  </Typography>
                )}
              </Grid>
            </div>
          </>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={clsx(classes.paper, classes.typoAlign, classes.customPaddingBtm)}>
              <Button
                id="legal-disclaimer-next-btn"
                variant="contained"
                size="large"
                className={clsx(globalThemeCss.containedBtn, classes.nextButton)}
                onClick={() => handleClick(routerHandling(context, 'next'))}
                disabled={nextDisabled}
              >
                Next
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
