import React from 'react';

import NoComponent from '../components/no-component';
import ContactForm from '../components/ast-components/contact-form';
import BuySell from '../components/ast-components/buying-selling';
import BuyLocation from '../components/ast-components/buy-location';
import SellLocation from '../components/ast-components/sell-location';
import LocationMap from '../components/ast-components/location-map';
import PropertyTypes from '../components/ast-components/property-types';
import PriveValue from '../components/ast-components/price-value';
import SellPriveValue from '../components/ast-components/sell-price-value';
import Lender from '../components/ast-components/lender';
import ThankYou from '../components/ast-components/astThankYou';
import MortgageApproval from '../components/ast-components/mortgage-approval';
import RealEstateAgent from '../components/ast-components/real-estate-agent';
import SpecificDates from '../components/ast-components/specific-dates';
import LegalAgreement from '../components/ast-components/legal-agreements';
import AgentList from '../components/ast-components/agent-list';
import ErrorPage from '../components/ast-components/ast-error';
import AstResidenceDisclaimer from '../components/ast-components/ast-residence-disclaimer';

const PageMap = {
  'Basic - Contact Info': ContactForm,
  'Basic - Buy Sell': BuySell,
  'Buy - Location': BuyLocation,
  'Sell - Location': SellLocation,
  'Basic - Location': LocationMap,
  'Sell - Property Type': PropertyTypes,
  'Buy - Property Types': PropertyTypes,
  'Buy - Price Range': PriveValue,
  'Sell - Value': SellPriveValue,
  'Basic - Real Estate Agent': RealEstateAgent,
  'Basic - Military Lender': Lender,
  'Basic - Mortgage Approval': MortgageApproval,
  'Basic - Specific Dates': SpecificDates,
  'Basic - Legal Agreements': LegalAgreement,
  'Basic - Residence Legal Disclaimer': AstResidenceDisclaimer,
  'agentlist': AgentList,
  'Thank You': ThankYou,
  'Error': ErrorPage
};

export default (screenType, data, context) => {
  if (typeof PageMap[screenType] !== 'undefined') {
    return React.createElement(PageMap[screenType], {
      screenType,
      data,
      context
    });
  }
  return React.createElement(NoComponent, {
    screenType
  });
};
